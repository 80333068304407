import React from 'react'
import { Box, Drawer, ListItemIcon, List, ListItemButton, ListItem, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getSvgpath } from '../HelperFile';
import { getMenuIcon } from './GetMenuIcon';


function IconDrawer({ mainMenu, menuArray, active, setActive, isSidebarOpen, languageDirection }) {
    const navigate = useNavigate()

    const onMenuClick = (menu) => {
        setActive(menu.subMenu_id)
        if (menu.Source_Type === 1) {
            // window.location.href = `dashboard${menu.subMenu_route}`
            // Split the route to extract workspaceId and viewId
            const parts = menu.subMenu_route.split('/');
            const workspaceId = parts[1];
            const viewId = parts[2];
            navigate('dashboard', {
                state: { worksapceid: workspaceId, viewid: viewId }
            });
            // navigate(`dashboard${menu.subMenu_route}`);
        } else {
            navigate(`/${menu.subMenu_route}`);
        }
    }
    return (
        <Drawer
         open={!isSidebarOpen}
        //  onClose={drawerClose}
         variant="persistent"
         anchor={languageDirection === 'rtl' ? 'right' : 'left'}
         transitionDuration={500}
         sx={{
             width: '55px',
             height: '100%',
             "& .MuiDrawer-paper": {
                 boxSizing: "border-box",
                 width: '55px',
                 mt:'48px',
                //  mt: '150px',
                //  ml:'15px',
                //  height: '420px',
                //  pt:'8%'
                //  display: 'flex',
                //  flexDirection: 'column',
                //  justifyContent: 'start',
                //  overflow: 'hidden',
                 border:'none'
             },
         }}
        >
            <Box width="100%">
                {mainMenu?.filter(item => item.menu_name !== 'Settings').map((menu) => (
                    // <List key={menu.menu_id}>
                    <React.Fragment key={menu.menu_id}>
                        {menuArray?.filter(element => element.menu_order === menu.menu_order).sort((a, b) => a.subMenu_order - b.subMenu_order)?.map((item, index) => (
                                <React.Fragment key={item.subMenu_id}>
                                    <ListItem disablePadding key={item.subMenu_id} sx={{mt:'10px'}}>
                                        <ListItemButton onClick={() => onMenuClick(item)}>
                                            <ListItemIcon><Tooltip  title={item.subMenu_name} placement="right">{getMenuIcon(item.SubMenu_Icon, active === item.subMenu_id ? '#489C8F' : '#5f6368', "20px")}</Tooltip></ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>
                                </React.Fragment>
                        ))}
                         </React.Fragment>
                    // </List>
                ))}
            </Box>
        </Drawer>
    )
}

export default IconDrawer