import React, { useState, useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LatestLogin from "../Assets/Images/LatestLogin 1.png"
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import RCORLOGO from '../Assets/Images/RCOR_DRK 1.png'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setToken, setMenu } from '../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, IconButton, useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withSplashScreen from '../components/withSplashScreen';
import axiosInstance from '../api/axiosInstance';
import MainHome from './MainHome';
import { onBoarding } from '../api/userApi';
import { handleApiErrors } from '../api/HandleApiErrors';

const API_URL = process.env.REACT_APP_BASE_URL
const SITE_KEY = process.env.REACT_APP_SITE_KEY

function Login() {
    // console.log(API_URL);
    const recaptchaRef = useRef(null);
    const token = localStorage.getItem("token")
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [viewPassword, setViewPassword] = useState(false)
    const [errMsg, setErrMsg] = useState()
    const [isValidDomain, setIsValidDomain] = useState(false)
    const [islogin, setIsLogin] = useState(false)
    const [isLoading, setIsLoading] = useState({})

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const fullURL = window.location.href;
    // console.log(fullURL);
    const parsedURL = new URL(fullURL)
    const hostName = parsedURL.hostname + (parsedURL.port ? `:${parsedURL.port}` : '')
    // const hostName = 'nanma.rcorintelliger.co'
    // console.log(hostName);
    //'demo.rcorintelliger.co'
    useEffect(() => {
        const getDomain = async () => {
            console.log(hostName);
            
            try {
                const response = await axios.post(`${API_URL}/auth/IsSubDomainValid`, {}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authority': hostName,
                        withCredentials: true,
                    },
                    // withCredentials: false,
                    mode: 'cors',
                })
                console.log(response);
                if (response.data.code === '101') {
                    // navigate('/invalid_url')
                    window.location.href = `https://rcorintelliger.co/InvalidURl?url=${hostName}`;
                }
                else {
                    setIsValidDomain(true)
                }
            }
            catch (error) {
                // alert(error.message)
                console.log(error);
            }
        }
        if(API_URL && hostName){
            getDomain()
        }
    }, [hostName, API_URL])

    const userlogin = async (userData) => {
        //hostName
        console.log(userData);
        setIsLogin(true)
        try {
            const response = await axios.post(`${API_URL}/auth`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authority': hostName,
                    withCredentials: true,
                    // 'mode' : 'cors'
                    // 'Origin': 'http://localhost:3000'
                },
                // withCredentials: false,
                mode: 'cors',
            })
            if (response.data.code === '101') {
                window.location.href = `https://rcorintelliger.co/InvalidURl?url=${hostName}`;
                return;
            }
            if (response.status === 401) {
                toast.error(response?.data?.message, {
                    autoClose: 5000
                })
                navigate('/loginpage')
                return
            }
            if (response.data?.token) {
                console.log(response);
                getOnboarding(response.data?.token, response?.data?.user)
                // getMenu(response.data?.user.usertypeid, response.data?.token, response?.data?.user)
                setErrMsg(null)
                setIsLogin(false)
            }
            else {
                recaptchaRef?.current.reset();  // Refresh the reCAPTCHA
                console.log(response);
                setIsLogin(false)
                toast.error(response.data.message, {
                    autoClose: 5000
                })
            }
        }
        catch (error) {
            recaptchaRef?.current?.reset();  // Refresh the reCAPTCH
            setIsLogin(false)
            console.log(error);
            if (error.response?.data?.message) {
                toast.error(error?.response?.data?.message, {
                    autoClose: 5000, // Close the toast after 5 seconds
                });
                setErrMsg(error?.response?.data?.message)
            }
            else {
                toast.error(error?.message, {
                    autoClose: 5000, // Close the toast after 5 seconds
                });
                setErrMsg(error?.message)
            }
        }
    }

         //api call to get on boarding details
         const getOnboarding = async (token, user, menu) => {
            try {
                const response = await axios.get(`${API_URL}/OnboardingProcess`, {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json',
                        'Authority': hostName,
                        withCredentials: true,
                    },
                    // withCredentials: false,
    
                })
                // const result = await onBoarding()
                console.log(response);
                if(response?.data?.OnboardingProcess && response?.data?.OnboardingProcess?.length !==0){
                    localStorage.setItem("token", token)
                    // localStorage.setItem("mainMenu", menu)
                    dispatch(setToken({ token: token }))
                    dispatch(setUser({ user: user }))
                    // dispatch(setMenu({ menu: menu }))
                    if(response?.data?.OnboardingProcess[0].Id === 4){
                        // navigate('/')
                        window.location.href = '/'

                    }else{
                        // navigate('/OnBoarding', { state: { boardingId: response?.data?.OnboardingProcess[0].Id } })
                        // navigate('/OnBoarding')
                        window.location.href = '/OnBoarding'
                    }
                }
            }
            catch (error) {
                console.log(error);
                if (error.response?.data?.message) {
                    toast.error(error?.response?.data?.message, {
                        autoClose: 5000, // Close the toast after 5 seconds
                    });
                    setErrMsg(error?.response?.data?.message)
                }
                else {
                    toast.error(error?.message, {
                        autoClose: 5000, // Close the toast after 5 seconds
                    });
                    setErrMsg(error?.message)
                }
                // handleApiErrors(error, 'OnBoarding', 'boarding_response', 'boarding_timeout', 'boarding_request', setIsLoading)
            }
        }

    const getMenu = async (userTypeId, token, user) => {
        console.log(userTypeId, user);
        try {
            const response = await axios.get(`${API_URL}/SubMenu/Permitted/${userTypeId}`, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'Authority': hostName,
                    withCredentials: true,
                },
                // withCredentials: false,

            })
      console.log(response);
            if (response?.data?.SubMenu && response?.data?.SubMenu?.length !== 0) {
                // localStorage.setItem("token", token)
                // dispatch(setToken({ token: token }))
                // dispatch(setUser({ user: user }))
                // dispatch(setMenu({ menu: response.data.SubMenu }));
                // navigate('/')
                getOnboarding(token, user, response.data.SubMenu)

            }
        }
        catch (error) {
            // recaptchaRef?.current.reset();  // Refresh the reCAPTCH

            console.log(error);
            // toast.error(error?.respose?.data?.message ? error?.respose?.data?.message : error?.message, {
            //     autoClose: 5000, // Close the toast after 5 seconds
            // });
            if (error.response?.data?.message) {
                toast.error(error?.response?.data?.message, {
                    autoClose: 5000, // Close the toast after 5 seconds
                });
            }
            else {
                toast.error(error?.message, {
                    autoClose: 5000, // Close the toast after 5 seconds
                });
            }

        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const captchaToken = await recaptchaRef?.current?.getValue();
        let userData = {
            "Username": username,
            "Password": password,
            "RecaptchaToken": captchaToken
        }
        console.log(userData);
        // let captcha = {
        //     "g-recaptcha-response": captchaToken,
        // }
        userlogin(userData)
    }


    return (
        <>{isValidDomain ?
            // <>{token ?
            //     <MainHome />
            //     :
                <Box className='boxstyle'>
                    <Grid container sx={{ maxWidth: '1920px' }}>
                        <Grid item xs={.5} md={2} ></Grid>
                        <Grid item xs={11} md={8}>
                            <Grid container>
                                <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }} className='load bgcolor'>
                                    <Box className='nodata-text' mt='10%'>
                                        {/* <Box >
                                    <img src={RCORLOGO} alt="LOGO" width={85} height={30} />
                                </Box> */}
                                        <Box mt={5} >
                                            <img src={process.env.PUBLIC_URL + '/assets/images/login_dashboard.png'} alt="Login" width={310} height={200} />
                                        </Box>
                                        <Box mt={4} className='box flex'>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/login_logo.png'} width={72} height={19} />
                                            <Typography className='typography heading-h'>INTELLIGER </Typography>
                                        </Box>
                                        <Box mt={3}>
                                            <span className='typography heading-h2' mt={1}> A Platform To Analyze Your Data </span>
                                        </Box>
                                    </Box>

                                </Grid>
                                <Grid item xs={12} md={6} className='load bgcolor-a4s'>
                                    <Box className='nodata-text' mt='8%'>
                                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/login_logo.png'} alt="LOGO" width={85} height={30} />
                                        </Box>
                                        <Box mt={1}>
                                            <Typography className='typography heading-h1' > Sign in </Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography className='typography heading-h4'>To Access RCOR Intelliger  </Typography>
                                        </Box>

                                    </Box>
                                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 5, pl: 3, pr: 3, mb: 5 }}  >

                                        <Typography className="typography heading-h3"> User Name </Typography>
                                        <TextField fullWidth size='small' className='textfiled style-1' required id="email" name="email" autoComplete="email" autoFocus
                                            InputProps={{
                                                className: 'textfiled style-1',
                                            }}
                                            value={username}
                                            onChange={(e) => { setUsername(e.target.value); setErrMsg(null) }} />
                                        <Typography className="typography heading-h3" mt={3}>
                                            {" "}
                                            Password{" "}
                                        </Typography>
                                        <TextField fullWidth size='small' className='textfiled style-1' required name="password" type={viewPassword ? 'text' : 'password'} id="password" autoComplete="current-password"
                                            // InputProps={{
                                            //     className: 'textfiled style-1'
                                            // }}
                                            InputProps={{
                                                className: 'textfiled style-1',
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => setViewPassword(!viewPassword)}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {viewPassword ? <VisibilityOff className="icon style-i" /> : <Visibility className="icon style-i" />}
                                                        </IconButton>
                                                    </InputAdornment>),
                                            }}
                                            value={password}
                                            onChange={(e) => { setPassword(e.target.value); setErrMsg(null) }}
                                        />
                                        <Box mt={2}>
                                          <ReCAPTCHA sitekey={SITE_KEY} ref={recaptchaRef} />
                                        </Box>
                                        <FormControlLabel control={<Checkbox value="remember" style={{ color: "#489C8F" }} />}
                                            label={<Typography className="typography heading-h3"> Remember Me </Typography>} />

                                        {!islogin && <Button type="submit" className='btn btn-log' > Sign In </Button>}
                                        {islogin && <Button className='btn btn-disable' ><span className="btn-text">Signing</span><div className="spinner"></div>
                                        </Button>}

                                    </Box>
                                    {/* <Box className='boxalignbtn'>
                                        <Grid container className='boxalign' >
                                            <Grid item xs={6} mt={1} className='boldstyle-f' >
                                                <Link href="#" className='link link-varient' >
                                                    Forgot password?
                                                </Link>
                                            </Grid>
                                            <Grid item xs={6} mt={1} className='boldstyle-f' mb={3}>
                                                <Link href="#" className='link link-varient'>
                                                    Sign Up
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Box> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={.5} md={2}></Grid>
                    </Grid>
                </Box>
            // }</> 
            :
            <></>
            // <Box display='flex' justifyContent='center' alignItems='center'>
            //     <CircularProgress />
            // </Box>
        }</>
    )
}

export default Login